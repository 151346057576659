import Link from "next/link";
import { useRouter } from "next/router";
import { batch, useDispatch } from "react-redux";
import { clearToken } from "../../../../features/auth/authSlice";
import { clearUserDetails } from "../../../../features/user/userSlice";
import {
  isParentPageActive,
  isSinglePageActive,
} from "../../../../utils/daynamicNavigation";
import { useContext, useState } from "react";
import UserContext from "../../../../features/user/UserContext";

const SidebarMenu = () => {
  const dispatch = useDispatch();
  const route = useRouter();

  const { user, setUser } = useContext(UserContext);

  const [name, setName] = useState(user ? user.fname + " " + user.lname : "");

  const myProperties = [
    { id: 1, name: "General Elements", route: "/my-listings" },
    { id: 2, name: "Advanced Elements", route: "/my-listings" },
    { id: 3, name: "Editors", route: "/my-listings" },
  ];
  const reviews = [
    { id: 1, name: "My Reviews", route: "/my-review" },
    { id: 2, name: "Visitor Reviews", route: "/my-review" },
  ];
  const manageAccount = [
    {
      id: 2,
      name: "My Profile",
      route: "/my-profile",
      icon: "flaticon-user",
    },
    {
      id: 3,
      name: "My Inquiries",
      route: "/my-inquiries",
      icon: "flaticon-envelope",
    },
  ];

  return (
    <>
      <ul className="sidebar-menu">
        <li className="sidebar_header header">
          <Link href="/">
            <a>
              <img
                src="/assets/images/logo/listabiz-logo-only-transparent-white.png"
                alt="header-logo2.png"
              />
              <span>LiztaBiz</span>
            </a>
          </Link>
        </li>
        {/* End header */}

        <li className="title">
          <span>
            <span className="flaticon-user mr-3"></span>
            <span>{name}</span>
          </span>

          <span className="mt-12">Main</span>
          <ul>
            <li
              className={`treeview ${
                isSinglePageActive("/create-listing", route.pathname)
                  ? "active"
                  : ""
              }`}>
              <Link href="/create-listing">
                <a>
                  <i className="flaticon-plus"></i>
                  <span> Add Listing</span>
                </a>
              </Link>
            </li>
          </ul>
        </li>
        {/* End Main */}

        <li className="title">
          <span>Manage Listings</span>
          <ul>
            <li
              className={`treeview ${
                isSinglePageActive("/my-listings", route.pathname)
                  ? "active"
                  : ""
              }`}>
              <Link href="/my-listings">
                <a>
                  <i className="flaticon-magnifying-glass"></i>
                  <span> My Listings</span>
                </a>
              </Link>
            </li>

            {/* <li
              className={`treeview ${
                isParentPageActive(reviews, route.pathname) ? "active" : ""
              }`}>
              <a data-bs-toggle="collapse" href="#review">
                <i className="flaticon-chat"></i>
                <span>Reviews</span>
                <i className="fa fa-angle-down pull-right"></i>
              </a>
              <ul className="treeview-menu collapse" id="review">
                {reviews.map((item) => (
                  <li key={item.id}>
                    <Link href={item.route}>
                      <a>
                        <i className="fa fa-circle"></i> {item.name}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </li> */}
            {/* End Review */}

            {/* <li
              className={`treeview ${
                isSinglePageActive("/my-favourites", route.pathname)
                  ? "active"
                  : ""
              }`}>
              <Link href="/my-favourites">
                <a>
                  <i className="flaticon-magnifying-glass"></i>
                  <span> My Favorites</span>
                </a>
              </Link>
            </li>
            <li
              className={`treeview ${
                isSinglePageActive("/my-saved-search", route.pathname)
                  ? "active"
                  : ""
              }`}>
              <Link href="/my-saved-search">
                <a>
                  <i className="flaticon-magnifying-glass"></i>
                  <span> Saved Search</span>
                </a>
              </Link>
            </li> */}
          </ul>
        </li>
        {/* End manage listing */}

        <li className="title">
          <span>Manage Account</span>
          <ul>
            {manageAccount.map((item) => (
              <li
                className={
                  isSinglePageActive(item.route, route.pathname) ? "active" : ""
                }
                key={item.id}>
                <Link href={item.route}>
                  <a>
                    <i className={item.icon}></i> <span>{item.name}</span>
                  </a>
                </Link>
              </li>
            ))}

            <li className="">
              <>
                <button
                  type="button"
                  onClick={async () => {
                    new Promise((res, rej) => {
                      res(
                        batch(() => {
                          dispatch(clearUserDetails());
                          setUser(null);
                          dispatch(clearToken());
                        })
                      );
                    }).then(() => {
                      window.location.href = "/login";
                    });
                  }}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    left: 0,
                    backgroundColor: "transparent",
                    border: "none",
                    zIndex: 100,
                  }}></button>
                <a style={{ zIndex: 1 }}>
                  <i className="flaticon-logout"></i>
                  <span> Log out</span>
                </a>
              </>
            </li>
          </ul>
        </li>
      </ul>
    </>
  );
};

export default SidebarMenu;
