const formatPrice = (
  price,
  style = "currency",
  currency = "PHP",
  params = {}
) => {
  const formatOptions = {
    style,
    notation: "compact",
    maximumFractionDigits: 2,
  };

  try {
    if (style === "currency") {
      formatOptions["currency"] = currency;
      formatOptions["currencyDisplay"] = "code";
    }

    if (params !== {}) {
      formatOptions = { ...formatOptions, params };
    }
    return Intl.NumberFormat("en-US", formatOptions).format(price);
  } catch (e) {
    return Intl.NumberFormat("en-US", formatOptions).format(price);
  }
};

export default formatPrice;
