import { api } from "../api/api";

const citiesAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    searchCities: builder.mutation({
      query: ({ name, province_name }) => ({
        url: "/city/search",
        method: "POST",
        body: { city_name: name, province_name },
      }),
    }),
  }),
});

export const { useSearchCitiesMutation } = citiesAPI;
