import React from "react";

import { BeatLoader } from "react-spinners";

export default function LoadingSpinner({
  loading = true,
  className = "",
  size = 20,
}) {
  return loading ? (
    <div
      className={`w-full h-full flex items-center justify-center mx-auto ${className}`}>
      <BeatLoader
        loading={loading}
        color={"gray"}
        aria-label="Loading Spinner"
        data-testid="loader"
        // className="mx-auto"

        size={size}
        // className="mx-auto max-w-[300px] max-h-[300px] !w-full !h-full"
      />
    </div>
  ) : null;
}
