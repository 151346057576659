import { useEffect } from "react";
import { useState } from "react";
import InputRange from "react-input-range";
import { useSelector, useDispatch } from "react-redux";
import { addPrice } from "../../features/properties/propertiesSlice";
import { setPrice, resetFilters } from "../../features/search/searchSlice";
import { BeatLoader } from "react-spinners";

import formatPrice from "../../utils/formatPrice";

/**
 * Price range slider
 * @returns
 */
const RangeSlider = () => {
  const { price } = useSelector((state) => state.search);
  const [priceValue, setPriceValue] = useState(price);
  const [clickedPriceValue, setClickedPriceValue] = useState(price);

  const [maxPrice, setMaxPrice] = useState(50000000);
  const [maxPriceLoading, setMaxPriceLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // get max price of listings from API
    const getMaxPrice = async () => {
      dispatch(setPrice({ min: 50000, max: 50000000 }));
      setMaxPriceLoading(true);

      const maxPriceRes = await fetch(
        `${process.env.SLS_URL}/listing/max-price`,
        {
          method: "GET",
        }
      );

      const maxPriceJson = await maxPriceRes.json();

      if (maxPriceJson && "maxPrice" in maxPriceJson) {
        setMaxPrice(maxPriceJson.maxPrice);

        // set slider value as maxPrice + 1 to display "unlimited" text on slider
        // dispatch(setPrice({ min: 50000, max: maxPriceJson.maxPrice + 100 }));
        // setPriceValue({ min: 50000, max: maxPriceJson.maxPrice + 100 });
      }

      try {
      } catch (e) {
        console.log(e);

        dispatch(setPrice({ min: 50000, max: 50000000 }));
      }

      setMaxPriceLoading(false);
    };

    getMaxPrice();
  }, []);

  const handleOnChange = (value) => {
    // setPriceValue({ min: value.min > 0 ? value.min : 50000, max: value.max });
    setPriceValue({
      min: value.min > 0 ? value.min : 50000,
      max: value.max,
    });
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(
        setPrice({
          min: priceValue.min,
          max: priceValue.max,
        })
      );
    }, 700);

    setClickedPriceValue(priceValue);

    return () => {
      clearTimeout(handler);
    };
  }, [priceValue]);

  const [clickMinPrice, setClickMinPrice] = useState(false);
  const [clickMaxPrice, setClickMaxPrice] = useState(false);

  return maxPriceLoading ? (
    <BeatLoader
      loading={maxPriceLoading}
      color={"white"}
      aria-label="Loading Spinner"
      data-testid="loader"
      size={10}
    />
  ) : (
    <div className="nft__filter-price tp-range-slider tp-range-slider-dark mb-20">
      <div className="nft__filter-price-inner d-flex align-items-center justify-content-between">
        {/* min price value */}
        <div className="nft__filter-price-box">
          <div className="d-flex align-items-center">
            <span
              onClick={(e) => {
                e.stopPropagation();
                setClickMinPrice(true);
                setClickMaxPrice(false);
              }}>
              {clickMinPrice ? (
                <input
                  type="number"
                  autoFocus
                  value={clickedPriceValue.min}
                  style={{ textAlign: "center" }}
                  onBlur={(e) => {
                    setClickMinPrice(false);
                    setClickMaxPrice(false);
                    setPriceValue(
                      clickedPriceValue.min < 50000
                        ? { ...clickedPriceValue, min: 50000 }
                        : {
                            min: parseInt(clickedPriceValue.min),
                            max: parseInt(clickedPriceValue.max),
                          }
                    );
                  }}
                  onChange={(e) => {
                    setClickedPriceValue({
                      ...priceValue,
                      min: parseInt(e.target.value),
                    });
                  }}
                />
              ) : (
                `${formatPrice(priceValue.min)}`
              )}
            </span>
          </div>
        </div>
        {/* max price value */}
        <div className="nft__filter-price-box">
          <div className="d-flex align-items-center">
            <span
              onClick={(e) => {
                e.stopPropagation();
                setClickMinPrice(false);
                setClickMaxPrice(true);
              }}>
              {clickMaxPrice ? (
                <input
                  type="number"
                  autoFocus
                  value={clickedPriceValue.max}
                  style={{ textAlign: "center", padding: 0 }}
                  onBlur={(e) => {
                    setClickMinPrice(false);
                    setClickMaxPrice(false);

                    setPriceValue(
                      clickedPriceValue.max <= priceValue.min + 10000 // look for a 10k gap between min and max price
                        ? {
                            ...clickedPriceValue,
                            max: parseInt(priceValue.max),
                          }
                        : {
                            min: parseInt(clickedPriceValue.min),
                            max: parseInt(clickedPriceValue.max),
                          }
                    );
                  }}
                  onChange={(e) => {
                    setClickedPriceValue({
                      ...priceValue,
                      max: parseInt(e.target.value),
                    });
                  }}
                />
              ) : (
                `${
                  priceValue.max > maxPrice
                    ? "Unlimited"
                    : formatPrice(priceValue.max)
                }`
              )}
            </span>
          </div>
        </div>
      </div>

      <InputRange
        formatLabel={(value) => ``}
        maxValue={maxPrice}
        minValue={50000}
        value={priceValue}
        onChange={(value) => {
          handleOnChange(value);
        }}
      />

      <div className="slider-styled inside-slider" id="nft-slider"></div>
    </div>
  );
};

export default RangeSlider;
