module.exports = [
  { label: "Agriculture", value: "agriculture" },
  { label: "Construction", value: "construction" },
  { label: "Cosmetics", value: "cosmetics" },
  { label: "Education", value: "education" },
  {
    label: "Entertainment & Production",
    value: "entertainment-production",
  },
  { label: "Fashion & Apparel", value: "fashion-apparel" },
  { label: "Finance", value: "finance" },
  { label: "Healthcare", value: "healthcare" },
  { label: "Hospitality", value: "hospitality" },
  { label: "Information Technology", value: "information-technology" },
  { label: "Manufacturing", value: "manufacturing" },
  { label: "Other", value: "other" },
  { label: "Real Estate", value: "real-estate" },
  { label: "Restaurant", value: "restaurant" },
  { label: "Retail", value: "retail" },
  { label: "Services", value: "services" },
  { label: "Transportation", value: "transportation" },
];
